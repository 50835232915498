<ul class="menu-list">
    <div class="container row">
        <div class="col-6">
            <ul class="vertical-list">
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Needles'}">
                        <a style="text-decoration: none;color: black;"> Needles
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Syringes'}">
                        <a > Syringes
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar"  [queryParams]="{ category: 'Cotton Wool'}">
                        <a >Cotton Wool
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Gloves'}">
                        <a > Gloves
                        </a>
                    </div>
                </li>
            </ul>
        </div>

        <div class="col-6">
            <ul class="vertical-list">
                <li>
                    <div class="title" routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Intravenous'}">
                        <a> Intravenous
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Diagnostics'}">
                        <a> Diagnostics
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Patient Monitoring'}">
                        <a> Patient Monitoring
                        </a>
                    </div>
                </li>
                <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar" [queryParams]="{ category: 'Gloves'}">
                        <a > Urinary
                        </a>
                    </div>
                </li>
                <!-- <li>
                    <div class="title"  routerLink="/shop/collection/right/sidebar">
                        <a > Consumables
                        </a>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</ul>
