import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshot, QueryDocumentSnapshot } from '@angular/fire/compat/firestore';
import { Product } from '../classes/product';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router, ActivatedRoute, NavigationExtras} from '@angular/router'
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';
import { arrayUnion } from '@firebase/firestore';




@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
    

  declare file: File;
  arrayBuffer:any;

  private productsCollection: AngularFirestoreCollection<Product>;
  products: Observable<Product[]>;
  private currentUserSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  currentUser$ = this.currentUserSubject.asObservable();
  public routeSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute) {

      this.route.queryParams.subscribe(params => {
        
        if(params['type']){
          console.log(params['type']);
          this.routeSubject.next(params['type']);
        }

      });

    this.productsCollection = this.db.collection<Product>('products');
    this.products = this.productsCollection.get().pipe(
      map((snapshot) => {
        console.log('here 2')
        console.log(snapshot.docs[0].data())
        if(this.routeSubject.value === 'medical'){
          const data = snapshot.docs[1].data();
          const products: Product[] = data['products'];
          localStorage.setItem('products', JSON.stringify(products));
          return products;
        } else {
          const data = snapshot.docs[0].data();
          const products: Product[] = data['products'];
          localStorage.setItem('products', JSON.stringify(products));
          return products;
        }
      })
    );
   }


  create(){
    const products = [
      {
          "id": 1,
          "title": "Healthease Nebu Mist Nebulizer",
          "description": "Nebulizer to help with breating",
          "type": "Medical",
          "brand": "Nebu Mist",
          "collection": ["on sale", "best sellers"],
          "category": "Medical",
          "price": 235,
          "sale": false,
          "discount": "30",
          "stock": 100,
          "new": false,
          "tags": [
              "Nebu Mist"
          ],
          "variants": [{
              "variant_id": 22401,
              "id": 224,
              "sku": "aaaaaa",
              "image_id": 22411
          }],
          "images": [{
              "image_id": 22411,
              "id": 224,
              "alt": "test",
              "src": "https://apps.digitaldistrict.co.za/neomedic-tst/scripts/uploads/Nebu%20mist%20nebulizer.png",
              "variant_id": [
                  22401
              ]
          }]
      },
       {
          "id": 2,
          "title": "Healthease Oxygen Nasal Cannula",
          "description": "Oxygen nasal cannula with 1.5m supply tube  For single patient use Latex free ",
          "type": "Air Management",
          "brand": "Healthease",
          "collection": ["best sellers"],
          "category": "Air Management",
          "price": 180,
          "sale": false,
          "discount": "15",
          "stock": 100,
          "new": false,
          "tags": [
              "Healthease"
          ],
          "variants": [{
                  "variant_id": 2201,
                  "id": 22,
                  "sku": "sku22",
                  "size": "xs",
                  "color": "yellow",
                  "image_id": 2211
              }
          ],
          "images": [{
                  "image_id": 2211,
                  "id": 22,
                  "alt": "yellow",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/sc…uploads/DigitalBloodPressureMonitor-wristtype.png",
                  "variant_id": [
                      2201,
                      2204
                  ]
              }
          ]
      },
      {
          "id": 3,
          "title": "Healthease KN95 protective face mask",
          "description": "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters,It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.",
          "type": "Comsumable",
          "brand": "denim",
          "collection": ["featured products"],
          "category": "Consumables",
          "price": 174,
          "sale": false,
          "discount": "40",
          "stock": 100,
          "new": true,
          "tags": [
             "Healthease"
          ],
          "variants": [{
                  "variant_id": 301,
                  "id": 3,
                  "sku": "sku3",
                  "size": "l",
                  "color": "white",
                  "image_id": 311
              },
              {
                  "variant_id": 302,
                  "id": 3,
                  "sku": "skul3",
                  "size": "m",
                  "color": "white",
                  "image_id": 311
              },
              {
                  "variant_id": 303,
                  "id": 3,
                  "sku": "sku3l",
                  "size": "l",
                  "color": "black",
                  "image_id": 312
              },
              {
                  "variant_id": 304,
                  "id": 3,
                  "sku": "sku4m",
                  "size": "m",
                  "color": "black",
                  "image_id": 312
              }
          ],
          "images": [{
                  "image_id": 311,
                  "id": 3,
                  "alt": "white",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/sc…uploads/DigitalBloodPressureMonitor-wristtype.png",
                  "variant_id": [
                      301,
                      303
                  ]
              },
              {
                  "image_id": 312,
                  "id": 1,
                  "alt": "white",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/sc…uploads/DigitalBloodPressureMonitor-wristtype.png",
                  "variant_id": [
                      302,
                      304
                  ]
              }
          ]
      }, {
          "id": 4,
          "title": "Healthease Cottonwool Non Sterile - Puffs",
          "description": "Ideal for cosmetic and baby use.Conveniently packed for Hosital and clinic use",
          "type": "Cotton Wool",
          "brand": "Healthease",
          "collection": ["new products", "on sale"],
          "category": "Cotton Wool",
          "price": 98,
          "sale": false,
          "discount": "50",
          "stock": 100,
          "new": true,
          "tags": [
            "Cotton Wool"
          ],
          "variants": [{
                  "variant_id": 401,
                  "id": 4,
                  "sku": "sku4",
                  "size": "s",
                  "color": "white",
                  "image_id": 411
              },
              {
                  "variant_id": 402,
                  "id": 4,
                  "sku": "skul4",
                  "size": "l",
                  "color": "white",
                  "image_id": 411
              },
              {
                  "variant_id": 403,
                  "id": 4,
                  "sku": "sku4s",
                  "size": "s",
                  "color": "skyblue",
                  "image_id": 412
              },
              {
                  "variant_id": 404,
                  "id": 4,
                  "sku": "sku4l",
                  "size": "l",
                  "color": "skyblue",
                  "image_id": 412
              }
          ],
          "images": [{
                  "image_id": 411,
                  "id": 4,
                  "alt": "white",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/sc…s/NEBU%20mist%20Nano%20Ultrasonic%20Nebulizer.png",
                  "variant_id": [
                      401,
                      402
                  ]
              },
              {
                  "image_id": 412,
                  "id": 4,
                  "alt": "skyblue",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/sc…s/NEBU%20mist%20Nano%20Ultrasonic%20Nebulizer.png",
                  "variant_id": [
                      403,
                      404
                  ]
              }
          ]
      }, {
          "id": 5,
          "title": "Healthease preg n care cassette pregnancy test",
          "description": "High sensitivity to HCG of 20mIU/ ml which  means pregnancy can be detected up to 7 days before the next period ",
          "type": "Diagnostics",
          "brand": "Preg `n Care",
          "collection": ["featured products"],
          "category": "Diagnostics",
          "price": 230,
          "sale": true,
          "discount": "20",
          "stock": 100,
          "new": true,
          "tags": [
              "Preg `n Care"
          ],
          "variants": [{
                  "variant_id": 501,
                  "id": 5,
                  "sku": "sku5",
                  "size": "m",
                  "color": "green",
                  "image_id": 511
              }
          ],
          "images": [{
                  "image_id": 511,
                  "id": 5,
                  "alt": "green",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/scripts/uploads/Contact%20Free%20Thermometer.png",
                  "variant_id": [
                      501,
                      503
                  ]
              }
          ]
      }, {
          "id": 6,
          "title": "Healthease Dental Needle",
          "description": "Ultra sharp needles for accuracy and comfort to minimze patient comfort during dental treatment",
          "type": "Needles",
          "brand": "Healthease",
          "collection": ["best sellers"],
          "category": "Needles",
          "price": 121,
          "sale": false,
          "discount": "40",
          "stock": 100,
          "new": true,
          "tags": [
              "Needles"
          ],
          "variants": [{
                  "variant_id": 601,
                  "id": 6,
                  "sku": "sku6",
                  "size": "s",
                  "color": "olive",
                  "image_id": 611
              },
              {
                  "variant_id": 602,
                  "id": 6,
                  "sku": "skul6",
                  "size": "s",
                  "color": "gray",
                  "image_id": 612
              }
          ],
          "images": [{
                  "image_id": 611,
                  "id": 6,
                  "alt": "olive",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/scripts/uploads/Dental%20Cover%20.png",
                  "variant_id": [
                      601,
                      604
                  ]
              }
          ]
      }, {
          "id": 7,
          "title": "Healthease Midrop Blood Glucose Meter",
          "description": "Kit includes- Meter, lancing device, 10 lancets, 2 AAA batteries, 10 test strips, and carry case ",
          "type": "Patient Monitoring",
          "brand": "Mi Drop",
          "collection": ["best sellers"],
          "category": "Patient Monitoring",
          "price": 290,
          "sale": true,
          "discount": "10",
          "stock": 150,
          "new": true,
          "tags": [
              "Patient Monitoring"
          ],
          "variants": [{
                  "variant_id": 701,
                  "id": 7,
                  "sku": "sku7",
                  "size": "s",
                  "color": "pink",
                  "image_id": 711
              },
              {
                  "variant_id": 702,
                  "id": 7,
                  "sku": "skul7",
                  "size": "s",
                  "color": "white",
                  "image_id": 712
              }
          ],
          "images": [{
                  "image_id": 711,
                  "id": 7,
                  "alt": "pink",
                  "src": "https://apps.digitaldistrict.co.za/neomedic-tst/scripts/uploads/MidropBGM.png",
                  "variant_id": [
                      701,
                      704
                  ]
              }
          ]
      }, {
          "id": 8,
          "title": "Healthease Plasterman Parffin Gauze Dressing",
          "description": "Each sterile dressing is packed in an aluminium peel open pouch.",
          "type": "Plasters",
          "brand": "Plasterman",
          "collection": ["new products"],
          "category": "Plasters",
          "price": 315,
          "sale": true,
          "discount": "70",
          "stock": 150,
          "new": false,
          "tags": [
              "Plasters"
          ],
          "variants": [{
                  "variant_id": 801,
                  "id": 8,
                  "sku": "sku8",
                  "size": "s",
                  "color": "yellow",
                  "image_id": 811
              },
              {
                  "variant_id": 802,
                  "id": 8,
                  "sku": "skul8",
                  "size": "s",
                  "color": "black",
                  "image_id": 812
              },
              {
                  "variant_id": 803,
                  "id": 8,
                  "sku": "sku8s",
                  "size": "m",
                  "color": "yellow",
                  "image_id": 811
              },
              {
                  "variant_id": 804,
                  "id": 8,
                  "sku": "sku8l",
                  "size": "m",
                  "color": "black",
                  "image_id": 812
              }
          ],
          "images": [{
                  "image_id": 811,
                  "id": 8,
                  "alt": "yellow",
                  "src": "assets/images/product/fashion/14.jpg",
                  "variant_id": [
                      801,
                      804
                  ]
              },
              {
                  "image_id": 812,
                  "id": 8,
                  "alt": "black",
                  "src": "assets/images/product/fashion/16.jpg",
                  "variant_id": [
                      802,
                      805
                  ]
              }
          ]
      }
  ];  
   
  this.db.collection('products').add({ products }).then((docRef) => {
  
    console.log('Products added successfully with document ID:', docRef.id);
  
  }).catch((error) => {
  
    console.error('Error adding products:', error);
  
  });

  }

  getProducts(): Observable<Product[]> {
    return this.products;
  }



  uplaodProducts() {

    const product =  {
      "id": 1,
      "title": "Healthease Nebu Mist Nebulizer", // product name
      "description": "Nebulizer to help with breating", //Product info
      "type": "Medical", // Category
      "brand": "Nebu Mist", //Brand
      "collection": ["on sale", "best sellers"], //none
      "category": "Medical",// Category
      "price": 235,// 0
      "sale": false,
      "discount": "30", //0
      "stock": 100,//none
      "new": false,//manual
      "tags": [
          "Nebu Mist" //brand
      ],
      "variants": [{
          "variant_id": 22401, // Product Code
          "id": 224, //auto generate id
          "sku": "aaaaaa", // product code
          "image_id": 22411,
          "size": 'xl' // description
      }],
      "images": [{
          "image_id": 22411, // auto-generate
          "id": 224, // auto-generate
          "alt": "test", // product name
          "src": "https://apps.digitaldistrict.co.za/neomedic-tst/scripts/uploads/Nebu%20mist%20nebulizer.png",
          "variant_id": [
              22401 //all variants
          ]
      }]
  };

// loop through column product name 
// Product Information is description
// type will be set manually
// if product name exists already , add the row a new variant
//
  }

  registerUser(email: string, password: string, userDetails: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.afAuth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
          const { uid } = userCredential.user;
          userDetails.id = uid;
          userDetails.isAdmin = false;
          const userCollectionRef = this.firestore.collection('users');
          return userCollectionRef.doc(uid).set(userDetails);
        })
        .then(() => {
          this.toastrService.success("Continue to explore while we review your application","Application Submitted")
          const queryParams = { type: 'medical' };
          const navigationExtras: NavigationExtras = {
            queryParams: queryParams
          };

          this.router.navigate(['shop/collection/left/sidebar'],navigationExtras)
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  login(email: string, password: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.afAuth.signInWithEmailAndPassword(email, password)
        .then(userCredential => {
          const { uid } = userCredential.user;
          const userCollectionRef = this.firestore.collection('users');
          return userCollectionRef.doc(uid).get()
            .pipe(
              map((userDoc: DocumentSnapshot<any>) => {
                return userDoc.data();
              })
            );
        })
        .then(userData => {
          userData.subscribe(data => {
            if(!data.approved) {
              this.toastrService.warning("Your account is currently being reviewed and will be approved soon");
            } else {
              this.currentUserSubject.next(data);
              this.toastrService.success("Welcome Back " + data.firstName)
            }
          });
          const queryParams = { type: 'medical' };
          const navigationExtras: NavigationExtras = {
            queryParams: queryParams
          };

          this.router.navigate(['shop/collection/left/sidebar'],navigationExtras)
          resolve();
        })
        .catch(error => {
          console.log("unable to login",error)
          this.toastrService.error("Incorrect Credentials, Please try again")
          reject(error);
        });
    });
  }

  logout(): Promise<void> {
    return this.afAuth.signOut().then(() => {
      this.toastrService.success("Successfully Logged Out, Hope to see you soon")
      this.currentUserSubject.next(null);
    });
  }

  
  fetchBrands(){
    return this.firestore.collection('brands').valueChanges();
  }
  
  incomingfile(event,type) {
  this.file= event.target.files[0]; 
  }

  public setData(type) {
    // this.loggedInuserId = id;
    let fileReader = new FileReader();
    // let fileWriter = new FileWriter();
    fileReader.onloadend = async (e) => {
      this.arrayBuffer = fileReader.result;
      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for(var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type:"binary"});
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];          
      console.log(XLSX.utils.sheet_to_json(worksheet,{raw:true}));
      this.createSheet(XLSX.utils.sheet_to_json(worksheet,{raw:true}),type);
      this.sessionSet('lpUid',XLSX.utils.sheet_to_json(worksheet,{raw:true}),120);
    }
    fileReader.readAsArrayBuffer(this.file);
    
  }


  createSheet(data,type){
    const notUplaoded = [];
    const productArray = [];
    
    // this.index.partialUpdateObjects(data,{
    //   createIfNotExists: false,
    // });

    // let recordid = "sU0Pc69kXqh7ax4UQ6yaZvum3qi3"
    let productCounter = 1;
    let variantCounter = 1;
    for (let index = 0; index < data.length; index++) {

        const pattern = /\r\n/g;
        // return data[index].replace(pattern, '');

        const productCode =  data[index]['Product Code'].replace(pattern, '')  

        const desc = data[index].Description.replace(pattern, '');

        // const info = data[index]['Product Information'].replace(pattern, '');
        
        // const infoArray = info.split(';').map(element => element.replace(/\r\n/g, ''));

        const info = data[index]['Product Information'];
        const infoArray = [info];

        // const pack = data[index]['Pack Size'].replace(pattern, '');
        
        variantCounter++;

            const productObj = {
                        id: productCounter,
                        title: this.capitalizeFirstLetters(data[index]['Product Name'].replace(pattern,'')),
                        description: desc.replace(/\r\n/g, ''),
                        type: data[index]['Category'],
                        brand: data[index]['Brand'],
                        collection: [],
                        category: data[index]['Category'],
                        price: 0,
                        sale: false,
                        discount: 0,
                        stock: 10000,
                        new: false,
                        tags: [data[index]['Brand'],data[index]['Category']],
                        variants : [
                            {
                                "variant_id": productCode, // Product Code
                                "id": variantCounter, //auto generate id
                                "sku": productCode, // product code
                                "image_id": 1,
                                "size": desc.replace(/\r\n/g, '')
                            }
                        ],
                            "images": [{
                                    "image_id": 1, // auto-generate
                                    "id": 1, // auto-generate
                                    "alt": "test", // product name
                                    "src": `https://apps.digitaldistrict.co.za/eezi-site/images/${productCode}.jpg`,
                                    "variant_id": [
                                        1 //all variants
                                    ]
                        }],
                        productInfo: infoArray,
                        // packSize: pack
                    };

                    const x = productArray.findIndex(x => x.title === productObj.title )

                    if(x !== -1 ){
                        productArray[x].variants.push(productObj.variants[0]);
                        productArray[x].images[0].variant_id.push(variantCounter);
                        
                    } else {
                        productCounter++;
                        productArray.push(productObj);
                    }

             
    
    }
    console.log(productArray);


    // this.db.collection('testProducts').add({ productArray }).then((docRef) => {
  
    //     console.log('Products added successfully with document ID:', docRef.id);
      
    //   }).catch((error) => {
      
    //     console.error('Error adding products:', error);
      
    //   });

   for(let product of productArray) {
    this.db.collection('products').doc('b4GMhUpcksbBaRAHsDiE').update({
        products: arrayUnion(product)
      });
   } 

  
      
  }

  sessionSet(key, value, expirationInMin) {
    let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin))
      let newValue = {
      value: value,
      expirationDate: expirationDate.toISOString()
    }
    window.sessionStorage.setItem(key, JSON.stringify(newValue));
  }


 capitalizeFirstLetters(sentence: string): string {
  return sentence.replace(/\b\w/g, (match) => match.toUpperCase());
}


saveNewletterEmails(email: string) {

    this.db.collection('emails').add({ email: email })
      .then(() => {
        console.log('Email added to Firestore collection');
        this.toastrService.success('Prepare to get the latest from us','Successfully Subscribed')
        // Optionally, display a success message to the user
      })
      .catch((error) => {
        console.log('Error adding email to Firestore collection:', error);
        // Handle the error and display an appropriate message to the user
      });

}

forgotPassword(email: string) {
    this.afAuth.sendPasswordResetEmail(email).then(data => {
        this.toastrService.success('You will receive an email to reset password');
    });
}








// processFolder(folderPath: string, images: any[] = []): ImageData[] {
//   const files = fs.readdirSync(folderPath);

//   for (const file of files) {
//     const filePath = path.join(folderPath, file);
//     const stats = fs.statSync(filePath);

//     if (stats.isDirectory()) {
//       // Recurse into subfolders
//       images = this.processFolder(filePath, images);
//     } else if (stats.isFile() && this.isImageFile(file)) {
//       // Process image files
//       const imageName = path.parse(file).name;
//       const category = path.basename(folderPath);
//       images.push({ name: imageName, category });
//     }
//   }

//   return images;
// }

// isImageFile(fileName: string): boolean {
//   // You can add more image extensions if needed
//   const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp'];
//   const ext = path.extname(fileName).toLowerCase();
//   return imageExtensions.includes(ext);
// }














}


// const product =  {
//     "id": 1,
//     "title": "Healthease Nebu Mist Nebulizer", // product name
//     "description": "Nebulizer to help with breating", //Product info
//     "type": "Medical", // Category
//     "brand": "Nebu Mist", //Brand
//     "collection": ["on sale", "best sellers"], //none
//     "category": "Medical",// Category
//     "price": 235,// 0
//     "sale": false,
//     "discount": "30", //0
//     "stock": 100,//none
//     "new": false,//manual
//     "tags": [
//         "Nebu Mist" //brand
//     ],
//     "variants": [{
//         "variant_id": 22401, // Product Code
//         "id": 224, //auto generate id
//         "sku": "aaaaaa", // product code
//         "image_id": 22411,
//         "size": 'xl' // description
//     }],
//     "images": [{
//         "image_id": 22411, // auto-generate
//         "id": 224, // auto-generate
//         "alt": "test", // product name
//         "src": "https://apps.digitaldistrict.co.za/neomedic-tst/scripts/uploads/Nebu%20mist%20nebulizer.png",
//         "variant_id": [
//             22401 //all variants
//         ]
//     }]
// };