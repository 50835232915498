<div class="bar-header sticky"  style="background-color: #f0f0f0;">
    <div>
        <svg class="call-svg" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.45 2.16667C3.5 2.90833 3.625 3.63333 3.825 4.325L2.825 5.325C2.48333 4.325 2.26667 3.26667 2.19167 2.16667H3.45ZM11.6667 12.1833C12.375 12.3833 13.1 12.5083 13.8333 12.5583V13.8C12.7333 13.725 11.675 13.5083 10.6667 13.175L11.6667 12.1833ZM4.25 0.5H1.33333C0.875 0.5 0.5 0.875 0.5 1.33333C0.5 9.15833 6.84167 15.5 14.6667 15.5C15.125 15.5 15.5 15.125 15.5 14.6667V11.7583C15.5 11.3 15.125 10.925 14.6667 10.925C13.6333 10.925 12.625 10.7583 11.6917 10.45C11.6083 10.4167 11.5167 10.4083 11.4333 10.4083C11.2167 10.4083 11.0083 10.4917 10.8417 10.65L9.00833 12.4833C6.65 11.275 4.71667 9.35 3.51667 6.99167L5.35 5.15833C5.58333 4.925 5.65 4.6 5.55833 4.30833C5.25 3.375 5.08333 2.375 5.08333 1.33333C5.08333 0.875 4.70833 0.5 4.25 0.5Z" fill="#1887D7"/>
        </svg>
        <label class="call-number" for="">(+27) 87 151 1182</label>

        <svg class="location-svg" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.5 8.5C6.46875 8.5 5.625 7.735 5.625 6.8C5.625 5.865 6.46875 5.1 7.5 5.1C8.53125 5.1 9.375 5.865 9.375 6.8C9.375 7.735 8.53125 8.5 7.5 8.5ZM13.125 6.97C13.125 3.8845 10.6406 1.7 7.5 1.7C4.35938 1.7 1.875 3.8845 1.875 6.97C1.875 8.959 3.70312 11.594 7.5 14.739C11.2969 11.594 13.125 8.959 13.125 6.97ZM7.5 0C11.4375 0 15 2.737 15 6.97C15 9.792 12.4969 13.1325 7.5 17C2.50313 13.1325 0 9.792 0 6.97C0 2.737 3.5625 0 7.5 0Z" fill="#1887D7"/>
        </svg>
        <label class="location-details" for="">Unit 8, Afrikano Centre
            150 Industrial Road,
            Johannesburg
            2092</label>
    </div>

    <app-settings class="offset-11"></app-settings>

</div>
<div class="bar-header">
    <div class="logo-container p-1" routerLink="/">
        <img src="../../../assets/img/eezi-logo.png" height="80">
        <p style="font-size: 16px;font-weight: 700;margin-top: 1%;"><span style="color: #0c4575;">EEZI </span> <span style="color: red;">HEALTH</span></p>

    </div>
    <div style="display: flex; justify-content: space-between; align-items: center;margin-left: 30%;">
        <a class="href-about nav-item" href="/">About</a>
        <div class="dropdown">
            <a class="href-shop  nav-item" [routerLink]="'/shop/collection/left/sidebar'" [queryParams]="{type:'medical'}" >Medical Supplies <span><i class="bi bi-caret-down-fill"></i></span></a>
           
        </div>
        <a class="href-shop  nav-item"  [routerLink]="'/shop/collection/left/sidebar'" [queryParams]="{type:'workwear'}">Workwear <span><i class="bi bi-caret-down-fill"></i></span></a>
        <a class="href-contact  nav-item" href="/">Contact Us</a>
    </div>
    <!-- <div class="dropdown-content">
        <app-nav-filter></app-nav-filter>
    </div> -->
</div>
