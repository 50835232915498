import { Component } from '@angular/core';

@Component({
  selector: 'app-range-items',
  templateUrl: './range-items.component.html',
  styleUrls: ['./range-items.component.scss']
})
export class RangeItemsComponent {

}
